import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import AboutUsColumns from "../components/AboutUs2Columns"

export default function AboutUsPage({ data }) {
  return (
    <Layout>
      <Seo title="About Us" />
      <AboutUsColumns image={data.truck} header="About Us">
        <p>
          Alpine Air Conditioning and Heating is a family-owned-and-operated
          business based in Pioneer, in the beautiful foothills of Amador
          County, California.
        </p>
        <p>
          Founded in 2005, we have built our business on our reputation for
          delivering quality products and service. Most important to us is our
          relationships with our customers, who are also our neighbors.
        </p>
        <p>We strive to provide only the best service, whether it concerns:</p>
        <ul className="ml-4 list-disc list-inside space-y-1">
          <li>new installation</li>
          <li>maintenance</li>
          <li>repairs of existing systems</li>
        </ul>
        <h3 className="mt-4 text-2xl font-extrabold tracking-wide text-blue">
          We offer
        </h3>
        <p>
          Affordable, honest, and dependable, outstanding service to people with
          whom we expect to have life-long relationships. Consequently, we find
          our customers are always recommending us to their friends, family, and
          neighbors. We can’t ask for any higher compliment!
        </p>
        <p>
          Our friendly, professionally trained employees are available to
          address any HVAC emergencies you may encounter 24 hours a day, 7 days
          a week. At Alpine Air Conditioning and Heating, WE EXCEED YOUR
          EXPECTATIONS!
        </p>
      </AboutUsColumns>
      <AboutUsColumns imgLeft image={data.jason} header="Meet Jason Woods">
        <p>
          Jason Woods has been installing and maintaining A/C and heating units
          for over 27 years; pretty much his entire adult life. After spending
          some years learning the trade, in 2005 he started Alpine Air
          Conditioning and Heating. He believes the best part of his job is
          meeting people, and the satisfaction that comes from helping them
          solve their air conditioning or heating problems.
        </p>
        <p>
          Deciding city life wasn’t the life for him, Jason left Livermore,
          California and started a new life in Amador County. Being an outdoor
          man, he loves gardening, raising chickens, caring for the apple, pear
          and cherry trees in his orchard, and playing with his Australian
          cattle dog and German shorthair mix. When winter weather permits, you
          may see him flying by on his snowmobile. Jason enjoys the “old town”
          feel of the Sierra Nevada foothills; folks waving to him whether he
          knows them or not, talking to strangers in the store, and being
          recognized by his first name when he enters the bank.
        </p>
        <p>
          Jason has fostered a friendly, almost family atmosphere in the
          workplace, making Alpine Air Conditioning and Heating a great place to
          work, and a company that focuses on the needs of people. “Exceeding
          your expectations” is not just a slogan to him, it is his business
          philosophy and the natural outcome of his drive to excel in everything
          he does.
        </p>
      </AboutUsColumns>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutUsQuery {
    truck: file(relativePath: { eq: "truck.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    jason: file(relativePath: { eq: "jason.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
