import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function AboutUsColumns(props) {
  return (
    <div className="overflow-hidden">
      <div className="relative px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none"></div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            className={`relative lg:row-start-1 ${
              props.imgLeft ? "lg:col-start-1" : "lg:col-start-2"
            }`}
          >
            <div className="relative mx-auto lg:max-w-none">
              <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                <GatsbyImage
                  image={getImage(props.image)}
                  className="object-cover object-center w-full rounded-lg shadow-lg"
                  imgStyle={{ objectPosition: "top" }}
                  alt="truck"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 text-gray-500 lg:mt-0">
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <div>
                <h2 className="text-2xl text-4xl font-extrabold tracking-wide sm:text-3xl text-blue">
                  {props.header}
                </h2>
              </div>
            </div>
            <div className="mx-auto mt-2 space-y-4 lg:max-w-none lg:row-start-1 lg:col-start-1">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
